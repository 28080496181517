<script setup>
import { loginViews, useLoginStore } from '@@/stores/Login';
import { useLoginView } from '@@/composables/useLoginView';
import { useUserStore } from '@@/stores/User';

defineProps({
  cardClassNames: {
    type: String,
    default: null,
  },
  isFullHeight: {
    type: Boolean,
    default: false,
  },
});

const loginStore = useLoginStore();
const userStore = useUserStore();

const {
  isChangeEmailLinkSentView,
  isDeleteAccountLinkSentView,
  isForgotPasswordLinkSentView,
  isLoginLinkSentView,
  isRegisterLinkSentView,
  isRegisterLinkVerifiedView,
} = useLoginView();
const { public: { siteName } } = useRuntimeConfig();

let registerCheckAttempts = 0;
let registerCheckIntervalId = null;

const email = computed(() => loginStore.credentials.email);
const passcodeValidate = computed(() => loginStore.credentials.passcode_validate);
const user = computed(() => userStore.user);

const emailSentTo = computed(() => email.value || 'you');

/**
 * Make a request to GET /user/register/check every 5 seconds to check whether the user
 * verified their email address in another tab or device. If the user verifies their email in
 * another tab or device then stop polling and display the registerLinkVerified view. Also try
 * to fetch the user so that if they verified in another tab in the same browser, the UI can be
 * updated to show the authenticated view.
 */
const pollRegisterCheck = async () => {
  registerCheckAttempts += 1;

  if (registerCheckAttempts > 120) {
    stopRegisterCheckPoll();
    return;
  }

  try {
    const { verified } = await loginStore.makeRegisterCheckRequest();

    if (verified) {
      stopRegisterCheckPoll();
      loginStore.setLoginView(loginViews.registerLinkVerified);
      await nextTick();
      userStore.fetchUser();
    }
  }
  catch (e) {
    stopRegisterCheckPoll();
  }
};

const startRegisterCheckPoll = () => {
  registerCheckIntervalId = window.setInterval(pollRegisterCheck, 5000);
};

const stopRegisterCheckPoll = () => window.clearInterval(registerCheckIntervalId);

onMounted(() => {
  if (isRegisterLinkSentView.value) {
    startRegisterCheckPoll();
  }
});

onUnmounted(stopRegisterCheckPoll);
</script>

<template>
  <Card
    :card-class-names="cardClassNames"
    :has-body-padding-responsive="true"
    :is-full-height="isFullHeight"
  >
    <template
      #body
    >
      <div class="tw-flex tw-flex-col lg:tw-flex-row tw-items-center lg:tw-mx-8 lg:tw-my-6">
        <font-awesome-icon
          :class="[
            'tw-block tw-w-14 lg:tw-w-20 tw-h-14 lg:tw-h-20',
            'tw-mb-4 lg:tw-mb-0 lg:tw-mr-8',
            $style.icon,
          ]"
          :icon="['fad', 'paper-plane']"
        />
        <div v-if="isLoginLinkSentView && passcodeValidate">
          <p class="tw-mb-3 lg:tw-mb-4">
            To log in to {{ siteName }}, click the button in the email we sent to
            {{ emailSentTo }}.
          </p>
          <p class="tw-mb-3 lg:tw-mb-4">
            <strong>Or</strong> enter the code from the email below.
          </p>
          <EnterPasscodeForm />
        </div>
        <p
          v-else-if="isLoginLinkSentView"
          class="tw-inline-block"
        >
          To log in to {{ siteName }}, click the button in the email we sent to
          {{ emailSentTo }}. Didn’t receive it? Check your spam folder or click the button below
          to resend.
        </p>
        <p
          v-else-if="isForgotPasswordLinkSentView"
          class="tw-inline-block"
        >
          To reset your password, click the button in the email we sent to {{ emailSentTo }}.
          Didn’t receive it? Check your spam folder or click the button below to resend.
        </p>
        <div
          v-else-if="isRegisterLinkSentView && passcodeValidate"
          class="tw-inline-block"
        >
          <p class="tw-mb-3 lg:tw-mb-4">
            To confirm your email, click the button in the email we sent to {{ emailSentTo }}.
          </p>
          <p class="tw-mb-3 lg:tw-mb-4">
            <strong>Or,</strong> enter the code from the email below.
          </p>
          <EnterPasscodeForm />
        </div>
        <p
          v-else-if="isRegisterLinkSentView"
          class="tw-inline-block"
        >
          To confirm your email, click the button in the email we sent to {{ emailSentTo }}. Didn’t
          receive it? Check your spam folder or click the button below to resend.
        </p>
        <p
          v-else-if="isRegisterLinkVerifiedView"
          class="tw-inline-block"
        >
          It looks like you may have registered in another tab or device. You can close this tab.
        </p>
        <p
          v-else-if="isChangeEmailLinkSentView"
          class="tw-inline-block"
        >
          To confirm your new email address, click the button in the email we sent to
          {{ emailSentTo }}. Didn’t receive it? Check your spam folder or click the button below to
          resend.
        </p>
        <p
          v-else-if="isDeleteAccountLinkSentView"
          class="tw-inline-block"
        >
          To delete your account, click the button in the email we sent to
          {{ (user && user.email) || 'you' }}. Didn't receive it? Check your spam folder or click
          the button below to resend.
        </p>
      </div>
    </template>
  </Card>
</template>

<style module>
.icon {
  color: var(--light-blue);
}
</style>
